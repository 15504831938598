<!-- eslint-disable vue/no-lone-template -->
<template>
  <s-dialog
    v-model:visible="visible"
    :show-close="true"
    class="bra-size-edit"
    :immediately-render="true"
    :append-to-body="true"
    type="W996"
  >
    <template #title>
      {{ language.SHEIN_KEY_PC_23536 }}
    </template>
    
    <div
      v-show="typeof recommendResult === 'object'"
      class="bra-size-edit__result"
    >
      <div
        v-if="recommendResult == 1"
        class="context"
      >
        {{ language.SHEIN_KEY_PC_19349 }}
      </div>
      <div
        v-if="typeof recommendResult === 'object'"
        class="size"
      >
        {{ recommendResult.size }}
      </div>
      <div
        v-if="typeof recommendResult === 'object'"
        class="fit"
      >
        {{ language.SHEIN_KEY_PC_23569 }}
      </div>
      <div
        v-if="!isExistedSize && typeof recommendResult === 'object' && !showPlusItem"
        class="not-available"
      >
        {{ language.SHEIN_KEY_PC_23530 }}
      </div>
      <div
        v-if="showSoldOut"
        class="sold-out"
      >
        {{ language.SHEIN_KEY_PC_23537 }}
      </div>
    </div>
    <div
      v-show="recommendResult == 1"
      class="bra-size-edit__result none"
    >
      <div class="fail-icon">
        <Icon
          :name="!isRw ? 'sui_icon_fail_face_96' : 'sui_icon_fail_face_96px'"
          size="46"
        />
      </div>
      <div class="context">
        {{ language.SHEIN_KEY_PC_23533 }}
      </div>
    </div>
    
    <div class="bra-size-edit__size-switch">
      <div class="my-size">
        {{ language.SHEIN_KEY_PC_17380 }}
      </div>
      <div
        v-if="recommendResult !== 0"
        @click="editSize"
      >
        <Icon
          class="edit-icon"
          name="sui_icon_edit_24px" 
          size="24px" 
        />
        <div class="edit-size">
          {{ language.SHEIN_KEY_PC_23548 }}
        </div>
      </div>
      <div class="switch-btn">
        <div
          class="in"
          :class="{active: unitType == 'inch'}"
          @click="unitTypeClick('inch')"
        >
          IN
        </div>
        <div
          class="cm"
          :class="{active: unitType == 'cm'}"
          @click="unitTypeClick('cm')"
        >
          CM
        </div>
      </div>
    </div>
    
    <div
      v-show="recommendResult == 0"
      class="bra-size-edit__form"
    >
      <label
        for="upper_bust"
        class="bra-size-edit__form-item"
      >
        <div
          class="placeholder"
          :class="{empty: inputActive.upper_bust}"
        >{{ language.SHEIN_KEY_PC_23546 }} ({{ unitType }})
        </div>
        <input
          id="upper_bust"
          v-model="form.upper_bust"
          type="text"
          placeholder
          @input="inputHandle('upper_bust', $event)"
          @focus="focusHandle('upper_bust')"
          @blur="blurHandle('upper_bust')"
        />
      </label>
      <label
        for="lower_bust"
        class="bra-size-edit__form-item"
      >
        <div
          class="placeholder"
          :class="{empty: inputActive.lower_bust}"
        >{{ language.SHEIN_KEY_PC_23547 }} ({{ unitType }})
        </div>
        <input
          id="lower_bust"
          v-model="form.lower_bust"
          type="text"
          placeholder
          @input="inputHandle('lower_bust', $event)"
          @focus="focusHandle('lower_bust')"
          @blur="blurHandle('lower_bust')"
        />
      </label>
    </div>
    <div
      v-show="recommendResult != 0"
      class="bra-size-edit__form"
    >
      <div class="top">
        <div class="label">
          {{ language.SHEIN_KEY_PC_23546 }}:
        </div>
        <div class="label">
          <template v-if="form.upper_bust !== ''">
            {{ form.upper_bust }}{{ unitType }}
          </template>
          <template v-else>
            —
          </template>
        </div>
      </div>
      <div class="bottom">
        <div class="label">
          {{ language.SHEIN_KEY_PC_23547 }}:
        </div>
        <div class="label">
          <template v-if="form.lower_bust !== ''">
            {{ form.lower_bust }}{{ unitType }}
          </template>
          <template v-else>
            —
          </template>
        </div>
      </div>
    </div>
    <div
      v-show="showError"
      class="bra-size-edit__err"
    >
      {{ language.SHEIN_KEY_PC_23534 }}
    </div>
    <div class="bra-size-edit__operation">
      <div
        v-if="recommendResult == 0"
        class="bra-size-edit__submit"
        @click="submitSize"
      >
        {{ language.SHEIN_KEY_PC_17178 }}
      </div>
      <div
        v-if="showContinue"
        class="bra-size-edit__submit continue"
        :class="{ 'bra-size-edit__white': showContinue && showSimilar }"
        @click="continueShopping"
      >
        {{ language.SHEIN_KEY_PC_23535 }}
      </div>
      <div
        v-if="showSimilar"
        class="bra-size-edit__submit find"
        @click="findSimilar"
      >
        {{ language.SHEIN_KEY_PC_16963 }}
      </div>
      <div
        v-if="showAddToBag"
        class="bra-size-edit__submit add"
        @click="addToBag"
      >
        {{ language.SHEIN_KEY_PC_14634 }}
      </div>
      <div
        v-if="showPlusItem"
        class="bra-size-edit__submit plus"
        @click="viewRelatedSize"
      >
        {{ language.SHEIN_KEY_PC_23531 }}
      </div>
    </div>
    
    <div
      v-if="sizeTemplate && recommendResult == 0"
      class="bra-size-edit__measure"
    >
      <div class="title">
        {{ language.SHEIN_KEY_PC_18043 }}
      </div>
      <div class="measure">
        <div
          v-if="sizeTemplate.context.length"
          class="measure__list"
        >
          <div
            v-for="(item, key) in sizeTemplate.context"
            :key="key"
            class="measure__item"
          >
            <div class="name">
              {{ item.sort }}.{{ item.name }}
            </div>
            <div class="content">
              {{ item.description }}
            </div>
          </div>
        </div>
        <div
          v-if="sizeTemplate.image"
          class="measure__image"
        >
          <img
            :src="sizeTemplate.image"
            alt
          />
        </div>
      </div>
    </div>
    <div
      v-if="recommendResult != 0"
      v-tap="{ id: '1-8-6-6', data: { goodsId: productIntroData.detail?.goods_id, from: 'size_suggestion' }}"
      v-expose="{ id: '1-8-6-139', data: { goodsId: productIntroData.detail?.goods_id, from: 'size_suggestion' }}"
      class="bra-size-edit__size-guid"
      @click="showSizeGuid"
    >
      {{ language.SHEIN_KEY_PC_16557 }}
      <Icon
        name="sui_icon_more_right_12px_1"
        size="12px"
        class="selected-icon"
      />
    </div>
  </s-dialog>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { transformImg } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue3'
import schttp from 'public/src/services/schttp'
import { mapActions } from 'vuex'
daEventCenter.addSubscriber({ modulecode: '1-8-6' })

let SimilarPopover = {}
if (typeof window !== 'undefined') {
  import('public/src/pages/common/similar_products').then(res => {
    SimilarPopover = res.default
  })
}

export default {
  components: { Icon },
  props: {
    language: {
      default() {
        return {}
      },
      type: Object
    },
    value: {
      type: Boolean,
      default: false
    },
    productIntroData: {
      default() {
        return {}
      },
      type: Object
    },
    unit: {
      default: 0, // 0 cm || 1 imch
      type: Number
    },
    saleAttrList: {
      type: Object,
      default: () => ({})
    },
    relatedGoodSizes: {
      type: Object,
      default: () => ({})
    },
    isRw: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:value', 'freshRecommendSize', 'sizeGuideModalShow', 'setGoodDetailFrom'],
  data() {
    return {
      unitType: 'cm',
      multiSelectOption: false,
      showError: false,
      recommendResult: 0, // 0 初始状态，没有推荐结果也没有查询推荐，1已经查询推荐，但是没有推荐数据，对象，推荐结果
      inputActive: {
        upper_bust: false,
        lower_bust: false
      },
      form: {
        upper_bust: '',
        lower_bust: '',
      },
      localsize: {
        EUR: 'EUR',
        US: 'US',
        UK: 'UK',
        BR: 'BR',
        CHN: 'CHN'
      },
      braSizeRecommendResult: {},
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('update:value', val)
      }
    },
    sizeTemplate() {
      const context =
        this.productIntroData.detail?.sizeTemplate?.description_multi || []
      const image = transformImg({
        img: this.productIntroData.detail?.sizeTemplate?.image_url
      })
      if(!context && !image) return null
      return { context, image }
    },
    multiLocalSize() {
      return this.productIntroData.multiLocalSize?.size_rule_list || {}
    },
    currentUnit() {
      return this.unit === 0 ? 'cm' : 'inch'
    },
    showSimilar() {
      return (!this.isExistedSize || this.showSoldOut) && (typeof this.recommendResult === 'object') && !this.showPlusItem// 尺码不存在或者尺码存在，但售罄时展示找相似
      // return (this.isExistedSize?.stock == 0 || this.showSoldOut) && this.recommendResult != 0// 尺码不存在或者尺码存在，但售罄时展示找相似
    },
    // 推荐的尺码是否存在
    isExistedSize() {
      const { letter_size = '', eu_size = '', us_size = '' } = this.braSizeRecommendResult || {}
      const skuList = this.saleAttrList?.sku_list ?? []
      return skuList?.find(item => {
        let skuSaleAttr = item?.sku_sale_attr ?? []
        let sizeInfo = skuSaleAttr.find(item => item.attr_id == 87) || {}
        return sizeInfo.attr_value_name === letter_size || sizeInfo.attr_value_name === eu_size || sizeInfo.attr_value_name === us_size
      })
    },
    // 推荐的尺码存在，但是没货
    showSoldOut() {
      return this.isExistedSize && this.isExistedSize?.stock == 0 && this.recommendResult != 0// stock为0、undefined时库存无
    },
    // 推荐尺码存在，且有货
    showAddToBag() {
      return this.isExistedSize && !this.showSoldOut && this.recommendResult != 0
    },
    showContinue() {
      return ((!this.isExistedSize) || this.showSoldOut) && (this.recommendResult != 0) && !this.showPlusItem
    },
    // 当前推荐的尺码不存在，但是能在关联尺码中找到
    showPlusItem() {
      const relatedGoodId = this.relatedGoodSizes?.goods_id
      const relatedGoodSizes = (this.relatedGoodSizes?.sale_attr_list?.[relatedGoodId]?.skc_sale_attr ?? []).find(item => item.attr_id == 87)
      const { letter_size = '', eu_size = '', us_size = '' } = this.braSizeRecommendResult || {}
      const relatedSize = relatedGoodSizes?.attr_value_list?.find(item =>
        item.attr_value_name === letter_size || item.attr_value_name === eu_size || item.attr_value_name === us_size)
      return !this.isExistedSize && relatedSize && (typeof this.recommendResult === 'object')
    },
    goodsId() {
      return this.productIntroData?.detail?.goods_id
    },
    resultType() {
      const hasResult = typeof this.recommendResult === 'object'
      if(hasResult && this.showAddToBag) {
        return 1 // 有匹配结果且有库存能引导用户加购，上报
      }
      if(hasResult && !this.isExistedSize && !this.showPlusItem) {
        return 2 // 有匹配结果，但当前商品没有对应的尺码，提示找相似
      }
      if(hasResult && this.isExistedSize?.stock == 0) {
        return 3 // 有匹配结果，但该结果无库存，提示找相似
      }
      if(hasResult && this.showPlusItem) {
        return 4 // 有匹配结果，当前商品没有对应的尺码，引导找商品关联尺码
      }
      if(this.recommendResult == 1) {
        return 0 // 无匹配结果
      }
      return ''
    }
  },
  watch: {
    visible(val) {
      if(val) {
        //  this.initVisible()
        sessionStorage.setItem('enable_size_recommend', '1')
      } else {
        if(this.recommendResult?.size) {
          this.$emit('freshRecommendSize', {
            size: this.recommendResult?.size,
            braRecommendSizes: this.recommendResult?.braRecommendSizes
          })
        }
      }
    },
  },
  mounted() {
    this.initVisible()
    if(this.checkForm() && this.productIntroData.rule_id)
      this.querySizeRecommend(true)
  },
  methods: {
    ...mapActions(['updateExposedList']),
    showSizeGuid() {
      this.$emit('sizeGuideModalShow', true)
    },
    inputHandle(key, e) {
      const {
        data,
        target: { value }
      } = e
      if(data === '.' && value.match(/\./g).length > 1) {
        const hasPoint = this.form[key].indexOf('.')
        this.form[key] = this.form[key].replace(/[^\d]/g, '')
        const sp = this.form[key].split('')
        sp.splice(hasPoint, 0, '.')
        this.form[key] = sp.join('')
      } else if(this.form[key] == '.') {
        this.form[key] = ''
      } else {
        this.form[key] = value.toString().replace(/[^\d|\.]/g, '')
      }
      if(/(\.\d{2,})$/.test(this.form[key])) {
        this.form[key] = (+this.form[key]).toFixed(2)
      }
      e.target.value = this.form[key]
      localStorage.setItem(
        'check_my_bra_size',
        JSON.stringify({ f: this.form, u: this.unitType })
      )
    },
    initVisible() {
      let sizeRecommend = localStorage.getItem('check_my_bra_size') || null
      try {
        sizeRecommend = /^\{/.test(sizeRecommend)
          ? JSON.parse(sizeRecommend)
          : null
      } catch(e) {
        sizeRecommend = null
      }
      if(sizeRecommend) {
        this.form = {
          ...this.form,
          ...(sizeRecommend.f || {})
        }
        this.unitTypeClick(sizeRecommend.u || this.currentUnit, false)
        // this.recommendResult = sizeRecommend?.s || 0
        this.focusHandle('upper_bust')
        this.focusHandle('lower_bust')
      } else {
        this.form = {
          upper_bust: '',
          lower_bust: ''
        }
        this.unitTypeClick(this.currentUnit, false)
      }
    },
    focusHandle(key) {
      this.inputActive[key] = true
    },
    blurHandle(key) {
      this.form[key] = this.form[key].replace(/\.$/, '')
      this.inputActive[key] = !!this.form[key]
    },
    unitTypeClick(size, isComputed = true) {
      this.unitType = size
      if(!isComputed) return
      const base = 0.3937007874
      if(/^\d/.test(this.form.upper_bust)) {
        this.form.upper_bust =
          +(this.unitType === 'inch'
            ? +this.form.upper_bust * base
            : +this.form.upper_bust / base
          ).toFixed(2) + ''
      }
      if(/^\d/.test(this.form.lower_bust)) {
        this.form.lower_bust =
          +(this.unitType === 'inch'
            ? +this.form.lower_bust * base
            : +this.form.lower_bust / base
          ).toFixed(2) + ''
      }
    },
    checkForm() {
      const { upper_bust, lower_bust } = this.form
      // 上下围用户只填写一个时，或穿都没有填
      if((!upper_bust || !lower_bust)) return false
      return true
    },
    async querySizeRecommend(isInit = false) {
      if(this.recommendResult !== 0) {
        this.recommendResult = 0
        this.showError = false
        return
      }
      this.showError = !this.checkForm()
      if(this.showError) {
        return
      }
      // 1: 内衣
      if(this.productIntroData?.rule_type != '1') {
        return
      }
      schttp({
        url: '/api/productInfo/braSizeRecommend/get',
        params: {
          skc: this.productIntroData.multiLocalSize?.skc,
          ...this.form,
          rule_id: 1, //this.productIntroData.rule_id,
          units: this.unitType
        }
      }).then(result => {
        // 字母码， 欧码、 美码 result || {}  { letter_size:"M",eu_size:"67A",us_size:"65A"}
        this.braSizeRecommendResult = result || {}
        const { letter_size = '', eu_size = '', us_size = '' } = this.braSizeRecommendResult
        if(letter_size || eu_size || us_size) { // 只要有一种尺码就要显示
          let hasParenthesis = (letter_size && (eu_size || us_size)) // 是否含有圆括号()
          let hasThroughLine = (eu_size && us_size) // 是否含有斜线分隔符 /
          let recommendSize = `${letter_size}${hasParenthesis ? ' (' : ''}${eu_size}${hasThroughLine ? '/' : ''}${us_size}${hasParenthesis ? ')' : ''}`
          this.recommendResult = {
            size: recommendSize,
            u: this.unitType,
            braRecommendSizes: [letter_size, eu_size, us_size]
          }
          window.sessionStorage.setItem('recommendMyBraSize', recommendSize)
          this.$emit('freshRecommendSize', {
            recommendMyBraSize: recommendSize,
            size: this.recommendResult?.size,
            isInit: isInit === true,
            // 所有的推荐尺码
            braRecommendSizes: this.recommendResult?.braRecommendSizes
          })
        } else {
          // localStorage.removeItem('check_my_size')
          this.recommendResult = isInit === true ? 0 : 1
          // if (isInit === true) this.form = { size_type: '', size: '', upper_bust: '', lower_bust: ''}
        }
        daEventCenter.triggerNotice({
          daId: '1-8-6-141',
          extraData: {
            goods_id: this.productIntroData.detail.goods_id,
            size_result: typeof this.recommendResult === 'object' ? 1 : 0,
            result_detail: this.resultType
          }
        })
      })
    },
    findSimilar() {
      daEventCenter.triggerNotice({
        daId: '1-8-6-110',
        extraData: {
          goods_id: this.goodsId,
          activity_from: 'size_suggestion'
        }
      })
      // 过滤掉空值
      const filterSize = Object.values(this.braSizeRecommendResult).filter(item => item) || []
      SimilarPopover.showModal(
        this.productIntroData?.detail,
        {
          similarFrom: 'out_of_stock',
          filterSize: filterSize.join(','),
          updateExposedList: this.updateExposedList
        }
      )
    },
    addToBag() {
      daEventCenter.triggerNotice({
        daId: '1-8-6-106',
        extraData: {
          goods_id: this.goodsId,
          activity_from: 'main',
        }
      })
      let sizeValue = Object.values(this.braSizeRecommendResult).find(value => {
        const elements = document.querySelectorAll(`.product-intro__size-radio[data-attr_value_name='${value}']`)
        return elements?.length !== 0
      })

      const willClickDom = document.querySelector(`.product-intro__size-radio[data-attr_value_name='${sizeValue}']`)

      let hasSelected = willClickDom?.classList?.contains?.('product-intro__size-radio_active')
      if (!hasSelected) {
        willClickDom?.click()
      }

      setTimeout(() => {
        this.$emit('update:value', false)
        const addButton = document.querySelector('.product-intro__add-btn button')
        addButton?.click()
      }, 1000)

    },
    continueShopping() {
      daEventCenter.triggerNotice({
        daId: '1-8-6-111',
        extraData: {
          goods_id: this.goodsId,
          activity_from: 'size_suggestion'
        }
      })
      this.$emit('update:value', false)
    },
    viewRelatedSize() {
      daEventCenter.triggerNotice({
        daId: '1-8-6-114',
        extraData: {
          goods_id: this.goodsId,
          activity_from: 'size_suggestion'
        }
      })
      setTimeout(() => {
        this.$emit('update:value', false)
        const relateGood = document.querySelector('.relate-good.product-intro__size-radio-spopover')
        if (relateGood) {
          const sizeRadios = relateGood.querySelectorAll('.product-intro__size-radio')
          sizeRadios?.forEach(radio => radio.click())
        }

      }, 1000)
    },
    editSize() {
      daEventCenter.triggerNotice({
        daId: '1-8-6-108',
        extraData: {
          goods_id: this.goodsId,
          activity_from: 'size_suggestion',
        }
      })
      this.querySizeRecommend()
    },
    submitSize() {
      daEventCenter.triggerNotice({
        daId: '1-8-6-140',
        extraData: {
          // goods_id: this.goodsId,
          // activity_from: 'size_suggestion',
        }
      })
      this.querySizeRecommend()
    }
  }
}
</script>

<style lang="less">
.bra-size-edit {
  padding: 40px;
  // font-family: ArialMT;
  .sui-dialog__body {
    width: auto !important; /* stylelint-disable-line declaration-no-important */
  }
  
  &__result {
    //background: #f6f6f6;
    padding-bottom: 31px;
    text-align: center;
    margin-bottom: 33px;
    
    .context,
    .none {
      font-size: 16px;
      color: #333333;
      line-height: 18px;
    }
    
    .fail-icon {
      margin-top: 13px;
      margin-bottom: 18px;
      
      // i {
      //   font-size: 46px;
      // }
    }
    
    .size {
      font-size: 24px;
      font-family: Arial-Black, Arial;
      font-weight: 700;
      color: #222222;
      line-height: 27px;
    }
    
    .fit {
      margin-top: 21px;
      margin-bottom: 23px;
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #333333;
    }
    
    .not-available, .sold-out {
      height: 32px;
      background-color: #FFF8EB;
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
      text-align: center;
      color: #A86104;
    }
  }
  
  &__size-switch {
    height: 26px;
    width: 100%;
    
    .my-size, .edit-size {
      display: inline-block;
      float: left;
      font-size: 18px;
      font-family: Arial-BoldMT, Arial;
      font-weight: normal;
      color: #000000;
      line-height: 24px;
    }
    
    .edit-icon {
      float: left;
      margin-left: 28px;
    }
    
    .edit-size {
      margin-left: 8px;
      font-size: 16px;
      font-weight: 400;
      color: #1860A7;
      cursor: pointer;
    }
    
    .switch-btn {
      display: inline-block;
      float: right;
      font-size: 12px;
      font-family: SFUIDisplay-Regular, SFUIDisplay;
      font-weight: 400;
      line-height: 14px;
      width: 74px;
      color: #222;
      
      div {
        display: inline-block;
        float: right;
        width: 35px;
        text-align: center;
        height: 23px;
        line-height: 23px;
        border: 1px solid #222222;
        cursor: pointer;
        user-select: none;
      }
      
      .active {
        background: #222222;
        color: #fff;
      }
    }
  }
  
  &__form {
    width: 100%;
    display: inline-block;
    
    .top,
    .bottom {
      display: flex;
      margin-top: 15px;
      font-size: 16px;
      color: #222222;
      line-height: 18px;
    }
    
    .label {
      margin-right: 14px;
    }
  }
  
  &__form-item {
    // height: 29px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: calc(100% - 20px);
    display: inline-block;
    cursor: text;
    padding: 0 10px;
    height: 47px;
    //float: left;
    position: relative;
    margin-top: 18px;
    
    .placeholder {
      position: absolute;
      z-index: -1; /* stylelint-disable-line declaration-property-value-blacklist */
      font-size: 12px;
      color: #0000003c;
      line-height: 14px;
      top: 7px;
      transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
      transform: translate3d(0, 9px, 0);
      
      &.empty {
        transform: translate3d(0, 0, 0);
      }
    }
    
    input {
      width: 100%;
      height: 14px;
      font-weight: bold;
      color: #222222;
      line-height: 0.45rem;
      border: none;
      outline: none;
      margin-top: 23px;
      margin-left: -2px;
      background: transparent;
      
      &::placeholder {
        font-size: 12px;
        font-weight: 100;
        color: #00000028;
      }
    }
    
    //&:nth-child(2) {
    //  margin-left: 20px;
    //}
    &.size {
      width: calc(100% - 20px);
      display: flex;
      flex-direction: column;
      
      .placeholder {
        position: relative;
        top: unset;
      }
      
      .local {
        display: flex;
        margin-top: 10px;
        
        &__selec {
          display: flex;
        }
        
        input {
          margin-top: unset;
        }
      }
    }
  }
  
  &__err {
    font-size: 12px;
    color: #ff411c;
    line-height: 14px;
    padding-top: 8px;
  }
  
  &__operation {
    display: flex;
    justify-content: center;
  }
  
  &__submit {
    font-size: 16px;
    font-weight: normal;
    color: #ffffff;
    width: 234px;
    height: 43px;
    line-height: 43px;
    background: #222222;
    margin: 16px 8px;
    text-align: center;
    cursor: pointer;
    user-select: none;
  }
  
  &__white {
    background: #fff;
    color: #222222;
    border: 1px solid #222222;
  }
  
  &__measure {
    margin-top: 39px;
    
    .title {
      font-size: 18px;
      font-family: Arial-BoldMT, Arial;
      font-weight: normal;
      color: #000000;
      line-height: 21px;
    }
    
    .measure {
      margin-top: 12px;
      display: block;
      
      &__list {
        display: inline-block;
        float: left;
        width: calc(100% - 219px);
        
        .name {
          font-size: 13px;
          color: #222222;
          line-height: 15px;
        }
        
        .content {
          font-size: 13px;
          margin-top: 8px;
          color: #222222;
          line-height: 15px;
        }
      }
      
      &__image {
        float: right;
        width: 176px;
        
        img {
          width: 100%;
        }
      }
      
      &__item:not(:first-child) {
        margin-top: 16px;
      }
    }
  }
  
  &__size-guid {
    cursor: pointer;
    user-select: none;
    margin: 0 auto;
    font-size: 12px;
    color: #1860a7;
    line-height: 16px;
    width: max-content;
  }
  
  &__units {
    position: relative;
    cursor: pointer;
    z-index: @zindex-hover;
    font-size: 14px;
    vertical-align: bottom;
    color: #222;
    
    ul {
      position: absolute;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
      border: 1px solid rgba(242, 242, 242, 1);
      padding: 5px 0;
      left: -11px;
      right: unset;
      top: 3px;
    }
    
    li {
      width: 138px;
      height: 36px;
      line-height: 36px;
      .padding-l(16px);
      cursor: pointer;
    }
  }
  
  .local {
    &__selected {
      display: flex;
      color: #22222250;
      font-size: 12px;
      cursor: pointer;
      white-space: nowrap;
      
      .selected-icon {
        margin: 0 14px 0 7px;
        font-weight: 600;
      }
      
      .line {
        width: 1px;
        height: 14px;
        background: #e5e5e5;
        margin-right: 10px;
      }
      
      &.active {
        color: #222;
      }
    }
  }
}
</style>
